/** @jsx jsx */
import Header from "../components/header"
import * as H from "../components/headings"
import * as C from "../components/containers"
import * as T from "../components/text"
import { graphql, Link, useStaticQuery } from "gatsby"
import { jsx, Spinner } from "theme-ui"

export default () => {
  const data = useStaticQuery(graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "gallery"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              intro
              subtitle1
              subtext1
              subtitle2
              subtext2
              subtitle3
              subtext3
              image1
              image2
              image3
              image4
              image5
              image6
              image7
              image8
              image9
          }
        }
      }
    }
  }
}`)

const frontmatter = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

const subtitleCircleText1 = frontmatter.subtitle1.split('').map(( letter, index ) => {
  let removeZero = (index + 1) * 8;
  let rotate = 360 - removeZero;
  return <span sx={{position: 'absolute', bottom: '0', left: '50%', height: '300px', width: '10px', lineHeight: '16px', transformOrigin: 'center', transform: `rotate(-${rotate}deg)`}}>{letter}</span>
})
const subtitleCircleText2 = frontmatter.subtitle2.split('').map(( letter, index ) => {
  let removeZero = (index + 1) * 8;
  let rotate = 360 - removeZero;
  return <span sx={{position: 'absolute', bottom: '0', left: '50%', height: '300px', width: '10px', lineHeight: '16px', transformOrigin: 'center', transform: `rotate(-${rotate}deg)`}}>{letter}</span>
})
const subtitleCircleText3 = frontmatter.subtitle3.split('').map(( letter, index ) => {
  let removeZero = (index + 1) * 8;
  let rotate = 360 - removeZero;
  return <span sx={{position: 'absolute', bottom: '0', left: '50%', height: '300px', width: '10px', lineHeight: '16px', transformOrigin: 'center', transform: `rotate(-${rotate}deg)`}}>{letter}</span>
})

  return (
    <div>
      <Header/>
      <C.centered sx={{pr: "2rem"}}>
        <H.h1>{frontmatter.title}</H.h1>
        <T.p>{frontmatter.intro}</T.p>
        <C.imgRow>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem', borderRadius: "50%" }}>
            <H.h4 sx={{ width: '100%', height: '100%', zIndex: '1',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>{subtitleCircleText1}</H.h4>
            <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}alt=""src={frontmatter.image1} />
          </div>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem',  }}>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image2} />
          </div>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem',  }}>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image3} />
          </div>
        </C.imgRow>
        <T.p>{frontmatter.subtext1}</T.p>
        <C.imgRow>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem', borderRadius: "50%"  }}>
             <H.h4 sx={{ width: '100%', height: '100%', zIndex: '1',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>{subtitleCircleText2}</H.h4>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image4} />
          </div>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem',  }}>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image5} />
          </div>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem',  }}>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image6} />
          </div>
        </C.imgRow>
        <T.p>{frontmatter.subtext2}</T.p>
        <C.imgRow>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem', borderRadius: "50%"  }}>
             <H.h4 sx={{ width: '100%', height: '100%', zIndex: '1',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}}>{subtitleCircleText3}</H.h4>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image7} />
          </div>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem',  }}>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image8} />
          </div>
          <div sx={{ width: '300px', position: 'relative', paddingTop: '300px', overflow: 'hidden', marginBottom: '1rem',  }}>
             <img sx={{ width: '100%', height: 'auto',  position: 'absolute', top: "50%", left: "50%", transform:"translate(-50%, -50%)"}} alt="" src={frontmatter.image9} />
          </div>
        </C.imgRow>
        <T.p>{frontmatter.subtext3}</T.p>
      </C.centered>
    </div>
  )
}